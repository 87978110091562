import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "./AddServiceProvider.css";
import { addServiceProviderApi } from "../components/APIs/Api";
import { uploadFile } from "react-s3";
import Lottie from "lottie-react";
import animationData from "../../images/loding.json";
import "react-phone-input-2/lib/style.css";
import AWS from "aws-sdk";

export default function AddServiceProvider({
  show,
  onHide,
  servicsProvidersList,
}) {
  ///===========s3 Config================

  const s3 = new AWS.S3();

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [nameEroor, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [AddDetailsModal, setAddDetailsModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectImage, setSelectImage] = useState();

  const handleUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };
  //=======================addVendor=====================
  async function hendelAddService(event) {
    event.preventDefault();
    let serviceAddFormData = { userName, email };
    console.log(serviceAddFormData, "service add dta");
    if (userName.length < 3) {
      setNameError("Invalid name");
      return;
    } else if (!email.includes("@")) {
      setEmailError("Invalid mail");
      return;
    }

    setLoader(true);

    let categoryImage = null;
    if (image instanceof File) {
      categoryImage = await handleUpload(image);
    }

    const response = await addServiceProviderApi(
      categoryImage?.Key,
      userName,
      email
    )
      .then((response) => {
        console.log(response?.status);
        if (response?.status === 200) {
          toast.success("Add successful!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoader(false);
          onHide();
          servicsProvidersList();
        }
      })
      .catch((error) => {
        console.log(error?.response, "errore");
        toast.error(`❌${error?.response?.data?.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoader(false);
      });
  }
  return (
    <Modal className="modal fade" show={show} centered>
      {loader ? (
        <div className="">
          <Lottie
            style={{ height: "200px", marginTop: "45px", padding: "30px" }}
            animationData={animationData}
          />
        </div>
      ) : (
        <div className="">
          <form onSubmit={hendelAddService}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">
                Add Service Provider Details
              </h4>
              <button
                type="button"
                className="btn close"
                onClick={() => onHide()}
              >
                <span>×</span>
              </button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Image</label>
                    <div className="mb-3">
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        required="required"
                        onChange={(e) => setImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Name</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        required="required"
                        placeholder="Name"
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <span className="text-danger">{nameEroor}</span>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">Email</label>
                    <div className="contact-name">
                      <input
                        type="email"
                        className="form-control"
                        required="required"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailError("");
                        }}
                      />
                      <span className="text-danger">{emailError}</span>
                    </div>
                  </div>
                  {/* <div className="form-group mb-3">
                    <label className="text-black font-w500">Phone</label>
                    <div className="contact-name">
                      <input
                        type="Number"
                        className="form-control"
                        required="required"
                        placeholder="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn pb-2 pt-2"
                onClick={() => setAddDetailsModal(true)}
                style={{ backgroundColor: "rgb(243, 108, 3)", color: "white" }}
              >
                Add
              </button>
              {/* <button
                type="button"
                onClick={() => onHide()}j
                className="btn btn-danger pb-2 pt-2"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Discard
              </button> */}
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
}
