import React from "react";
import { servicesProviderDetailsApi } from "../components/APIs/Api";
import { variable, s3BaseUrl } from "../components/Variable";
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import S3Image from "../components/S3Image";

export default function ServiceProviderDetails(props) {
  const [details, setDetails] = useState();
  const [loader, setLoader] = useState(true);
  const ele = props?.location?.state?.item;
  async function serviceDetails() {
    const res = await servicesProviderDetailsApi(
      props?.location?.state?.item?._id
    );
    setDetails(res);
    setLoader(false);
  }
  useEffect(() => {
    serviceDetails();
  }, []);

  const item = details?.data?.data;

  return (
    <>
      {loader ? (
        <div className="">
          <Lottie
            style={{ height: "200px", marginTop: "45px", padding: "30px" }}
            animationData={animationData}
          />
        </div>
      ) : (
        <div>
          <div className="page-titles">
            <h4>Vendor Details</h4>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ width: "70%" }}
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="vendor-management">Vendor</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link>Vendor Details</Link>
                </li>
              </ol>
              <button
                className="edit-button"
                onClick={() => {
                  props.history.push({
                    pathname: "/edit-vendor",
                    state: { item: ele },
                  });
                }}
              >
                <i className="fa fa-edit  "></i>
              </button>
            </div>
          </div>
          <Card style={{ width: "70%" }}>
            <Card.Header>
              <div className="d-flex" style={{ gap: "1rem", flexGrow: "1" }}>
                <div>
                  {/* <img
                    style={{
                      height: "132px",
                      width: "132px",
                      objectFit: "cover",
                    }}
                    src={`${s3BaseUrl}${item?.image}`}
                    width={110}
                    height={110}
                    className="text-center profile-img"
                  /> */}
                  <S3Image imageKey={item?.image} title={"details"} />
                </div>
                <div className="text-left" style={{ flex: "1" }}>
                  <h3 className="text-color ">{item.userName}</h3>
                  <div className="d-flex justify-content-between align-items-center bio-name p-2">
                    <h5 className="m-0">Email</h5>
                    <p className="m-0">{item.email}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center bio-name p-2 mt-1">
                    <h5 style={{ margin: "0" }}>Phone</h5>
                    <p style={{ margin: "0" }}>
                      <span>{item.countryCode}</span>
                      {item.phoneNumber}
                    </p>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black pt-2">
                <div className="d-flex justify-content-between align-items-center p-3 bio-user">
                  <h5 className="m-0">Business Name</h5>
                  <p className="m-0">{item?.businessName}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">TypeOfBusiness</h5>
                  <p className="m-0">{item?.typeOfBusiness}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">Address</h5>
                  <p className="m-0 w-50 " style={{ textAlign: "end" }}>
                    {item?.address}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">ZipCode</h5>
                  <p className="m-0">{item?.zipCode}</p>
                </div>
                <div className="bio-user mt-2">
                  <h5 style={{ margin: "0", padding: "15px 10px 0px 10px " }}>
                    OperatingHour
                  </h5>
                  {item?.operatingHours?.map((item) => (
                    <p style={{ margin: "0px", padding: "12px" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="m-0 w-30">&#x2022; {item?.day} </span>
                        {item?.status ? (
                          <span>closed</span>
                        ) : (
                          <div>
                            <span className="m-0 w-30">{item?.startTime} </span>{" "}
                            - <span className="m-0 w-30">{item?.endTime} </span>
                          </div>
                        )}
                      </div>
                    </p>
                  ))}
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">WebSite Url</h5>
                  <p className="m-0">{item?.websiteUrl}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">Created At</h5>
                  <p className="m-0">{moment(item?.createdAt).format("ll")}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center p-3 mt-2 bio-user">
                  <h5 className="m-0">Updated At</h5>
                  <p className="m-0">{moment(item?.updatedAt).format("ll")}</p>
                </div>
                <div className="bio-user mt-2">
                  <h5 style={{ margin: "0", padding: "15px 10px 0px 10px " }}>
                    About
                  </h5>
                  <p style={{ marginLeft: "10px", textAlign: "start" }}>
                    {" "}
                    <span>&#x2022; </span>
                    {item?.about}
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
}
