import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { userManagementList } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import { variable, s3BaseUrl } from "../components/Variable";
import FilterSearch from "../components/Filters/FilterSearch";
import { deleteUserApi } from "..//components/APIs/Api";
import { userCommingPlatform } from "../components/Filters/FilterLables";
import { blockUserApi } from "..//components/APIs/Api";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../components/common/Pagination";
import S3Image from "../components/S3Image";
export default function UserManagement(props) {
  const [loader, setLoader] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filterType, setFilterType] = useState("");
  const [city, setCity] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const itemsPerPage = 5;

  //================userList================
  async function userListData() {
    setLoader(true);
    const res = await userManagementList(
      search,
      currentPage,
      itemsPerPage,
      startDate,
      endDate,
      filterType,
      city
    );
    setUsersList(res);
    setLoader(false);
  }

  //===========pagination===================
  const totalItems = usersList?.data?.data?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  //==============Block User=================
  async function blockUser(id, status) {
    const res = await blockUserApi(id, status);
    setLoader(false);
    userListData();
    if (status === 1) {
      toast.success("Active succesful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("DeActive succesful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  //===============DeleteUser================
  async function hendleDeleteUser(UserId) {
    const res = await deleteUserApi(UserId);
    userListData();
    if (res.status === 200) {
      toast.success("Delete succesful!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error("error !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  useEffect(() => {
    userListData();
  }, [filterType]);

  useEffect(() => {
    if (city.length > 0) {
      setCurrentPage(0);
      userListData();
    } else if (city.length === 0) {
      userListData();
    }
  }, [city]);

  useEffect(() => {
    if (search.length > 0) {
      setCurrentPage(0);
      userListData();
    } else if (search.length === 0) {
      userListData();
    }
  }, [search]);

  useEffect(() => {
    userListData();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    if (endDate.length > 0) {
      setCurrentPage(0);
      userListData();
    } else if (endDate.length === 0) {
      userListData();
    }
  }, [endDate]);

  useEffect(() => {
    userListData();
  }, []);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#f2f7f7" cx="5" cy="12" r="2"></circle>
        <circle fill="#f2f7f7" cx="12" cy="12" r="2"></circle>
        <circle fill="#f2f7f7" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      <div>
        <PageTitle activeMenu="Users List" motherMenu="Users" />

        {/* //===========filterData======================//// */}
        <FilterSearch
          setSearch={setSearch}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          FilterOption={userCommingPlatform}
          setFilterType={setFilterType}
          userListData={userListData}
          title={"Heard about from"}
        />
        <FilterSearch setSearch={setCity} Type="off" type="search" />

        {usersList?.data?.data?.total === 0 ? (
          <h2 className="text-center">No user</h2>
        ) : (
          <Col>
            {loader ? (
              <div className="">
                <Lottie
                  style={{
                    height: "200px",
                    marginTop: "45px",
                    padding: "30px",
                  }}
                  animationData={animationData}
                />
              </div>
            ) : (
              <Card>
                <Card.Body>
                  <Table responsive>
                    <thead style={{ color: "black" }}>
                      <tr>
                        <th>
                          <strong>IMAGE</strong>
                        </th>
                        <th>
                          <strong>NAME</strong>
                        </th>
                        <th>
                          <strong>EMAIL</strong>
                        </th>
                        <th>
                          <strong>Heard about from</strong>
                        </th>
                        <th>
                          <strong>age</strong>
                        </th>
                        <th>
                          <strong>STATUS</strong>
                        </th>
                        <th>
                          <strong>ACTION</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersList?.data?.data?.users.map((item, i) => (
                        <tr key={item.id}>
                          <td>
                            <S3Image imageKey={item?.image} />
                          </td>
                          <td>
                            {item?.userName?.length > 10
                              ? item?.userName?.slice(0, 15) + "..."
                              : item?.userName || "N/A"}
                          </td>
                          <td>{item.email || "N/A"}</td>
                          <td>{item.aboutUs}</td>
                          <td>{item.age || "N/A"}</td>{" "}
                          <td>
                            {item.isBlocked === false ? (
                              <Badge variant="success light">Active</Badge>
                            ) : (
                              <Badge variant="danger light">Blocked</Badge>
                            )}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="warning light"
                                className="light sharp btn i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    variable.id = item._id;
                                    props.history.push("/user-details");
                                  }}
                                >
                                  View Details
                                </Dropdown.Item>

                                {item.isBlocked === false ? (
                                  <Dropdown.Item
                                    onClick={() => blockUser(item._id, 0)}
                                  >
                                    InActive
                                  </Dropdown.Item>
                                ) : (
                                  <Dropdown.Item
                                    onClick={() => blockUser(item._id, 1)}
                                  >
                                    Active
                                  </Dropdown.Item>
                                )}
                                <Dropdown.Item
                                  onClick={() => hendleDeleteUser(item._id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {/* <div className="pagination-buttons">
                   <button className="prev-button"
                     onClick={() => goToPage(currentPage - 1)}
                     disabled={currentPage === 0}
                   >
                     Prev
                   </button>
                   <span className="page-indicator">
                     Page {currentPage} of {totalPages}
                   </span>
                   <button className="next-button"
                     onClick={() => goToPage(currentPage + 1)}
                     disabled={currentPage === totalPages}
                   >
                     Next
                   </button>
                 </div> */}
                  {usersList?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          {/* currentPage {currentPage * itemsPerPage + 1} totalPages{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "} */}
                          Total Users{" "}
                          <span
                            style={{
                              color: "rgb(243, 108, 3)",
                              fontWeight: "bold",
                              fontSize: "16px",
                            }}
                          >
                            {totalItems}
                          </span>
                        </div>
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Col>
        )}
      </div>
    </>
  );
}
